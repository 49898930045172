import { useLocation } from "react-router-dom";
import { LocationsListing } from "src/pages/Home/components/PropertyCollection";
import HomeVector from "../HomeVector";
import GalleryWithLoader from "../GalleryWithLoader/GalleryWithLoader";

type MapCardProps = {
  onClose: () => void;
  selectedLocation: LocationsListing
}

const MapCard = ({
  onClose,
  selectedLocation
}: MapCardProps) => {

  const redirectUrl = process.env.REACT_APP_REDIRECT_URL

  const location = useLocation()

  const builder_name =  location?.pathname?.split('/')[1]

  const handleClick = () => {
      window?.open(`${redirectUrl}${builder_name}/property/${selectedLocation?.property_id}`, '_blank')
  }

  return (
    <div className="map-card d-flex"
    >
      <div className="card-img-container">
        {selectedLocation?.images.length > 0 ?
            <GalleryWithLoader
                items={selectedLocation?.images.map((image) => ({ original: image }))}
            />
          :
          <HomeVector />
        }
      </div>
      <div onClick={handleClick} className="map-card-content">
        {/* <p
          className="close-icon d-flex justify-content-center align-items-center"
          onClick={onClose}
        >
          &#10005;
        </p> */}
        <h4>{selectedLocation?.address_1}</h4>
        <p>PropertyID: {selectedLocation?.property_id}</p>
        <div className="d-flex align-items-end gap-1 mt-2">
          <h5 className="pb-0 m-0">${selectedLocation?.price?.toLocaleString()}</h5>
          {/* <h5 className="mb-0">/ $2,291/mo.*</h5> */}
        </div>
        <div className="room-info d-flex align-items-center md-my-0 my-3">
          <div className="info-box">
            <h6>Bed</h6>
            <p>{selectedLocation?.bedrooms}</p>
          </div>
          <div className="info-box">
            <h6>Fba</h6>
            <p>{selectedLocation?.fullBathrooms}</p>
          </div>
          <div className="info-box">
            <h6>Hba</h6>
            <p>{selectedLocation?.halfBathrooms}</p>
          </div>
          <div className="info-box">
            <h6>SQ. FT.</h6>
            <p>{selectedLocation?.squareFootage}</p>
          </div>
        </div>
        <div>
          <p>{selectedLocation?.subdivision}, {selectedLocation?.city}, {selectedLocation?.state}, {selectedLocation?.zipcode}</p>
          <p className="md-py-0 py-1">House No. {selectedLocation?.house_num}</p>
          {/* <p>New-Never Occupied</p> */}
        </div>
      </div>
    </div>
  );
};

export default MapCard;
